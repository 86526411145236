/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages
        this.mainMenu();
        this.postCalc();
        this.sameHeightBoxes();
        this.formLabelOrdNum();
        this.hoverDropdown();
      }, hoverDropdown: function () {
        $('.menu-item-has-children').hover(function () {
            if ($(window).width() >= 992) {
              $(this).addClass("open");
              $(this).children('a').css('padding-right', '34px');
              $(this).children('a').children('span').hide();
            }
          }, function () {
            if ($(window).width() >= 992) {
              $(this).removeClass("open");
              $(this).children('a').children('span').show();
              $(this).children('a').css('padding-right', '20px');
            }
          }
        );
      },
      mainMenu: function () {


        $('nav .nav li.menu-item').each(function () {
          if ($(this).children('.sub-menu').length !== 0) {
            console.log('.sub-menu');
          } else {
            console.log('nincs');
          }
          //asdsgfsdefjug
        });

      },
      postCalc: function () {
        //form settings
        //and style

        $('#calculate').click(function () {
          var currentUrl = window.location.protocol + '//' + window.location.hostname;

          var area = parseInt($('input[name=area]').val().trim());
          var verydirty = parseInt($('input[name=verydirty]').val());
          var jarmuTipus = $('select[name=jarmutipus]').val();
          var jarmu_szenny;
          if ($('span.checkbox[data-name=jarmu_szenny]').hasClass('checked')) {
            jarmu_szenny = 1;
          } else {
            jarmu_szenny = 0;
          }

          if (verydirty === '') {
            verydirty = 0;
          }

          var where = $('input[name=where]').val();
          $("#where, #area, #verydirty").removeClass('error');
          if (where.trim() !== '') {
            $.post(currentUrl + "/wp-content/themes/ozon/calc.php", {
              where: where
            }).done(function (data) {
              var summ;
              var km = Math.round(parseFloat(data));
              var kmdij = parseInt($('#kmdij').text());
              var penznem = ' Ft';
              var utikoltseg;
              var jarmu;
              var jarmuSzenny;
              //dsfh
              var helyiseg;
              var erosszenny = 0;

              if (isNaN(km)) {
                utikoltseg = 0;
                penznem = 'Érvénytelen adatok.';
              } else {
                if (km === 0) {
                  utikoltseg = 0;
                } else {
                  utikoltseg = km * kmdij;
                }

              }
              var dijak = {
                30: 4725,
                60: 8660,
                100: 12600,
                110: 13000,
                120: 13400,
                130: 13800,
                140: 14200,
                150: 14600,
                160: 15000,
                170: 15400,
                180: 15800,
                190: 16200,
                200: 16500,
                210: 17000,
                220: 17300,
                230: 17700,
                240: 18100,
                250: 18500
              };

              var autok = {
                szemelyauto: 1970,
                furgon: 2400,
                kisbusz: 2700,
                lakokocsi: 4720,
                busz: 9800,
                kamion: 4500,
                teherauto: 4500
              };

              switch (jarmuTipus) {
                case 'szemelyauto':
                  jarmu = autok.szemelyauto;
                  break;
                case 'furgon':
                  jarmu = autok.furgon;
                  break;
                case 'kisbusz':
                  jarmu = autok.kisbusz;
                  break;
                case 'lakokocsi':
                  jarmu = autok.lakokocsi;
                  break;
                case 'busz':
                  jarmu = autok.busz;
                  break;
                case 'kamion':
                  jarmu = autok.kamion;
                  break;
                case 'teherauto':
                  jarmu = autok.teherauto;
                  break;
              }

              var key;
              for (key in dijak) {
                if (area <= key) {
                  helyiseg = dijak[key];
                  break;
                }
              }
              var ke;
              var varDij;
              for (ke in dijak) {
                if (verydirty <= ke) {
                  if (ke > 30) {
                    varDij = 1400;
                  } else {
                    varDij = 1000;
                  }
                  erosszenny = Math.round((dijak[ke] * 1.8) + varDij);
                  break;
                }
              }

              if (area > 250) {
                helyiseg = dijak[250] * (area / 250);
              }
              if (verydirty > 250) {
                varDij = 1400;
                erosszenny = (dijak[250] * (verydirty / 250)) * 1.8 + varDij;
              }
              if (verydirty === 0) {
                erosszenny = 0;
              }
              var error = false;
              if ($('span.checkbox[data-id=ingatlan]').hasClass('checked')) {
                if (typeof helyiseg === "undefined") {
                  summ = '<span style="color: #a94442;">A szennyezett terület méretének megadása szükséges!</span>';
                  $("#area").addClass('error');
                  error = true;
                } else if (area < verydirty) {
                  summ = '<span style="color: #a94442;">Az erősen szennyezett terület nem lehet nagyobb, mint a szennyezett terület!</span>';
                  $("#area").addClass('error');
                  $("#verydirty").addClass('error');
                  error = true;
                } else {
                  summ = utikoltseg + helyiseg + erosszenny;
                }
              } else if ($('span.checkbox[data-id=jarmu]').hasClass('checked')) {
                if (jarmu_szenny === 1) {
                  varDij = 1000;
                  jarmuSzenny = jarmu * 1.8 + varDij;
                } else {
                  jarmuSzenny = 0;
                }
                summ = utikoltseg + jarmu + jarmuSzenny;
              }
              if (!error) {
                summ = Math.round(summ * 1.27) + penznem;
              }

              console.log('---------------');
              console.log('Helyiség ára: ' + helyiseg + ' Ft');
              console.log('Távolság: ' + km + ' Km');
              console.log('Útiköltség: ' + utikoltseg + ' Ft');
              console.log('Jármű alap: ' + jarmu + ' Ft');
              console.log('Várakozási díj: ' + varDij + ' Ft');
              console.log('Erős szennyezettség: ' + erosszenny + ' Ft');
              console.log('Jármű szennyezettség: ' + jarmuSzenny + ' Ft');
              console.log('Összesen: ' + summ);


              $('span.summ').text('Számolás...').html(summ);
            });
          } else {
            $('span.summ').text('Számolás...').html('<span style="color: #a94442;">Település nevének megadása kötelező!</span>');
            $("#where").addClass('error');
          }
        });
      },
      sameHeightBoxes: function () {
        var highest = 0;
        $('.colorful-boxes').each(function () {
          if ($(this).children('.box').children('p').height() > highest) {
            highest = $(this).children('.box').children('p').height();
          }
        });
        $('.colorful-boxes').children('.box').children('p').height(highest);
      },
      formLabelOrdNum: function () {
        /*    if ($('#choice_5_0').is(':checked')) {
         $('#field_2_6, #field_2_8, #field_2_12, #field_2_14').show();
         $('#field_2_7').hide();
         }*/

        var length = $('label.gfield_label').length;
        var countedIndex;
        $('label.gfield_label').each(function (i) {
          var text = $(this).text();
          var desc = $(this).next('.gfield_description').text();
          i++;
          var numero = parseInt(i) + 1;
          var ordNum;

          if (i < 9) {
            ordNum = '<span class="ord_number">' + i + '</span>';
            countedIndex = i;
          } else if (i > length - 2) {
            countedIndex++;
            ordNum = '<span class="ord_number">' + countedIndex + '</span>';
          } else {
            ordNum = '';
          }


          $(this).parent('.gfield').prepend('<div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">' + ordNum + '<label>' + text + '</label><p>' + desc + '</p></div>');
          $(this).remove();
          $(this).next('.gfield_description').remove();
        });
        $('.ginput_container').addClass('col-sm-6 col-md-6 col-lg-6 col-xs-12 col');
        $('.clear-multi').addClass('col-sm-12 col-md-12 col-lg-12 col-xs-12 col');
        $('.clear-multi .ginput_container').removeClass('col-sm-6 col-md-6 col-lg-6 col-xs-12 col');
        $('input[type=checkbox],input[type=radio]').parent().prepend('<span class="checkbox"></span>');


        $('.checkbox').each(function () {
          $(this).attr('data-type', $(this).next('input').attr('type'));
          $(this).attr('data-name', $(this).next('input').attr('name'));
          $(this).attr('data-id', $(this).next('input').attr('id'));
          if ($(this).next('input').is(':checked')) {
            $(this).text('X').addClass('checked');
          } else {
            $(this).text('').removeClass('checked');
          }
          $(this).next('input').hide();
        });

        $('span.checkbox').click(function () {
          var checkbox = $(this);
          var type = $(this).attr('data-type');
          var name = $(this).attr('data-name');
          var id = $(this).attr('data-id');
          console.log(type, ' ', name, ' ', id);
          if (type === 'radio') {
            $(this).next().attr('checked', 'checked');
            if ($(this).parent().parent().attr('id') === 'input_2_5') {
              if ($(this).next().next().text() === 'Gépjárműre') {
                $('#field_2_17, #field_2_8, #field_2_14, #field_2_6, #field_2_12').hide();
                $('#field_2_7, #field_2_18').show();
              } else if ($(this).next().next().text() === 'Helyiségre') {
                $('#field_2_17, #field_2_8, #field_2_14, #field_2_6, #field_2_12').show();
                $('#field_2_7, #field_2_18').hide();
              }
              // gf_apply_rules(2,[17,8,14,6,12,7,18]);
            }
            if ($(this).parent().parent().hasClass('arak_calculator')) {
              $(this).parent().nextAll().children('span').removeClass('checked');
              $(this).parent().prevAll().children('span').removeClass('checked');
              $(this).addClass('checked');
              if ($(checkbox).text() !== 'X') {
                $(this).parent().parent().parent().parent().parent().children().each(function (index, value) {
                  if ($(value).attr('data-id') === 'jarmu' || $(value).attr('data-id') === 'ingatlan') {
                    $(value).toggle();

                  }
                });
              }
            }
            if ($(this).text() !== 'X') {
              //Ez teszi rá az X-et, ha nincs még rajta
              $(this).parent().prevAll().children('span').text('');
              $(this).parent().nextAll().children('span').text('');
              $(this).text('X');
            }
          } else {
            if ($(this).hasClass('checked')) {
              $('input#' + id).prop('checked', false);
              $(this).text('').removeClass('checked');
            } else {
              $('input#' + id).prop('checked', true);
              $(this).text('X').addClass('checked');
            }
          }
        });
        $('#choice_2_5_0').siblings('label').css('cursor', 'pointer');
        $('#choice_2_5_0').click(function () {
          $(this).parent().siblings().children('span.checkbox').text('');
          $(this).siblings('span.checkbox').text('X');
        });
        $('#choice_2_5_1').siblings('label').css('cursor', 'pointer');
        $('#choice_2_5_1').click(function () {
          $(this).parent().siblings().children('span.checkbox').text('');
          $(this).siblings('span.checkbox').text('X');
        });
        $('.gfield_description').remove();
        $('.gfield[data-id=ingatlan]').show();
        $('.gfield[data-id=jarmu]').hide();
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
